let AgoraRTC = window.AgoraRTC
const Agora_sdk = {
  client: null,
  roomStatus: '',
  playCamera: '',
  options: {
    appid: '',
    channel: "Mrstage",
    uid: 9000111,
    token: null,
    role: "host",
    audienceLatency: 2,
  },
  levelCallback: () => { },
  // 当前频道状态监听
  connectionStateChange: (val) => { },
  // 用户加入房间监听
  userJoined: (val) => { },
  // 用户离开房间监听
  userLeft: (val) => { },
  // 订阅远端视频流
  userPublished: (val) => { },
  // 取消订阅远端视频流
  userUnpublished: (val) => { },
  // 网络状态
  networkQuality: (val) => { },
  init: () => {
    // AgoraRTC.setLogLevel(1);
    AgoraRTC.enableLogUpload();
    Agora_sdk.client = AgoraRTC.createClient({
      mode: "live",
      codec: "vp8",
      role: Agora_sdk.options.role,
    });
    Agora_sdk.client.on("network-quality", (stats) => {
      Agora_sdk.networkQuality(stats)
    });
    Agora_sdk.client.on("connection-state-change", (a, b) => {
      Agora_sdk.connectionStateChange({
        key: a,
        oldkey: b
      })
    });
    Agora_sdk.client.on("user-joined", (a) => {
      console.log(a);
      // Agora_sdk.client.enableAudioVolumeIndicator();
      Agora_sdk.userJoined({
        key: 'user-joined',
        msg: `用户ID为${a.uid}加入了房间`,
        uid: a.uid
      })
    });
    Agora_sdk.client.on("user-left", (a) => {
      console.log(a, '离开房间');
      Agora_sdk.userLeft({
        key: 'user-left',
        msg: `用户ID为${a.uid}离开了房间`,
        uid: a.uid
      })
    });
    Agora_sdk.client.on("user-published", async (user, mediaType) => {
      console.log(
        "+====user-published,用户ID为",
        user.uid,
        "的推送了",
        mediaType,
        "类型的流"
      );


      // 发起订阅
      await Agora_sdk.client.subscribe(user, mediaType);
      Agora_sdk.userPublished({
        user,
        mediaType,
      })
    });
    Agora_sdk.client.on("user-unpublished", (user, mediaType) => {
      Agora_sdk.userUnpublished({
        user,
        mediaType,
      })
    });
  },
  // 加入房间
  joinRoom: async (val) => {
    console.log(val);
    if (Agora_sdk.roomStatus) {
      console.log('请勿重复加入房间');
      return
    }
    Agora_sdk.roomStatus = await Agora_sdk.client.join(
      Agora_sdk.options.appid,
      Agora_sdk.options.channel,
      Agora_sdk.options.token,
      Number(Agora_sdk.options.uid),

    );
  },
  // 离开房间
  leaveRoom: async () => {
    console.log(Agora_sdk.client, 'Agora_sdk.roomStatus');
    // if (!Agora_sdk.roomStatus) {
    //   console.log('请加入房间后再离开');
    //   return
    // }
    if (Agora_sdk.client) {
      // await Agora_sdk.stopTheCamera(Agora_sdk.playCamera)
      // await Agora_sdk.client.unpublish();
      await Agora_sdk.stopTheCamera()
      await Agora_sdk.stopTheMic()
      await Agora_sdk.client.leave();
    }
    Agora_sdk.client = ''
    Agora_sdk.roomStatus = ''
  }
}
export default Agora_sdk;

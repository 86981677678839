export default {
  data() {
    return {

    }
  },
  computed: {
  },
  watch: {

  },
  // beforeDestroy() {
  //   this.$rtmSocket.wsLogout()
  // },
  // mounted() {
  //   this.wsinit()
  // },
  methods: {
    wsinit() {
      this.$rtmSocket.wsLogin({
        uid: this.$randomString(6),
        channelid: this.channelNum
      })
      this.$rtmSocket.wsChannelJoin = (val) => {
        console.log(val)
      }
      this.$rtmSocket.wsLoginSuccess = (val) => {
        console.log(val)
      }
      this.$rtmSocket.wsLogout = (val) => {
        console.log(val)
      }
      this.$rtmSocket.wsInterrupted = (val) => {
        console.log(val)
      }
      this.$rtmSocket.wsRemoteLogin = (val) => {
        console.log(val)
      }
      // this.$rtmSocket.localSortMessage = (val) => {

      // }
    },
  }
}

<template>
  <div class="demo">
    <div class="demo_left" v-loading="loading" :element-loading-text="loadingText"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div id="playVideoqny"></div>
    </div>
    <div class="demo_right">
      <div class="righttop">
        <el-input class="ipt" type="textarea" v-model="text" placeholder="输入文字驱动数字人"></el-input>

        <div>
          <el-button style="margin-left: 30px" type="primary" @click="wenzijieguan">文字接管</el-button>
        </div>
        <div class="iptupload">
          <input ref="fileRef" type="file" class="file-btn" @change="changeupLoadImg" />
          <el-button style="margin-left: 30px" type="primary">mp3接管</el-button>
        </div>
        <div>
          <el-button style="margin-left: 30px" type="primary" @click="maikefengjieguan">{{ micFlag ? "关闭麦克风接管" : "麦克风接管"
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as ws from "../utils/ws";
import {
  startPlay,
  validAccessToken,
  Livetakeover,
  qnyCreateV2,
  heartbeat,
  shuzirenclose,
} from "@/api";
import Agora_sdk from "@/utils/agora";
import VoiceToText from "./VoiceToText";
import rtm from "../socket/rtm";
import { Upload } from "./upload";

export default {
  mixins: [VoiceToText, rtm, Upload],
  data() {
    return {
      imgFlag: true,
      channelNum: "",
      answer: "开始说话",
      sessionId: "",
      text: "",
      loading: false,
      loadingText: "数字人加载中",
      timers: null,
      tokens: null,
      wsmic: null,
      micFlag: false,
      recorders: null,
      sessionToken: null,
      obj: {
        version: "0.0.1",
        recycle: 1, //0重复1不重复
        sceneList: [
          {
            digital_role: {
              id: 1,
              face_feature_id: "0325_nina_s3_beauty",
              name: "小李",
              url: "https://dwg-aigc-paas-test.oss-cn-hangzhou.aliyuncs.com/materials/77/0325_nina_s3_beauty.zip",
              position: { x: 10, y: 60 },
              scale: 1,
            },
            tts_config: {
              id: "nina",
              name: "nina",
              vendor_id: 3,
              language: "zh-CN",
              pitch_offset: 0,
              speed_ratio: 1,
              volume: 400,
            },
            tts_query: {
              content: "《随幻空间·品牌虚拟演播厅》正式上线",
              ssml: false,
            },
            audios: [
              {
                url: "https://virtual-human-online.oss-cn-hangzhou.aliyuncs.com/mayahui/%E5%95%86%E6%B1%A4%E9%9F%B3%E9%A2%91%E9%A9%B1%E5%8A%A8%E6%9C%80%E6%96%B0.MP3",
              },
            ],
            backgrounds: [
              {
                type: 0,
                name: "背景",
                url: "https://dwg-aigc-paas.oss-cn-hangzhou.aliyuncs.com/test/background.png",
                rect: [0, 0, 1080, 1920],
                cycle: true,
                start: 0,
                duration: -1,
              },
            ],
            foregrounds: [
              {
                type: 0,
                name: "前景1",
                url: "https://dwg-aigc-paas.oss-cn-hangzhou.aliyuncs.com/test/frontgroud.png",
                rect: [0, 0, 310, 88],
                start: 0,
                duration: 100,
              },
            ],
            effects: {
              version: "1.0",
              beautify: {
                whitenStrength: 1,
                whiten_mode: 0,
                reddenStrength: 0.36,
                smoothStrength: 1,
                smooth_mode: 0,
                shrinkRatio: 1,
                enlargeRatio: 1,
                smallRatio: 0.1,
                narrowFace: 1,
                roundEyesRatio: 0,
                thinFaceShapeRatio: 0,
                chinLength: 0,
                hairlineHeightRatio: 0,
                appleMusle: 0,
                narrowNoseRatio: 0,
                noseLengthRatio: 0,
                profileRhinoplasty: 0,
                mouthSize: 0,
                philtrumLengthRatio: 0,
                eyeDistanceRatio: 0,
                eyeAngleRatio: 0,
                openCanthus: 0,
                brightEyeStrength: 0,
                removeDarkCircleStrength: 0,
                removeNasolabialFoldsStrength: 0,
                whiteTeeth: 0,
                shrinkCheekbone: 0,
                thinnerHead: 0,
                openExternalCanthus: 0,
                shrinkJawbone: 0,
                shrinkRoundFace: 0,
                shrinkLongFace: 0,
                shrinkGoddessFace: 0,
                shrinkNaturalFace: 0,
                shrinkWholeHead: 0,
                contrastStrength: 0.05,
                saturationStrength: 0.1,
                sharpen: 0,
                clear: 0,
                bokehStrength: 0,
              },
            },
          },
        ],
      },
    };
  },
  watch: {},
  mounted() {
    this.initST();
    Agora_sdk.init();
    this.recOpen();
  },
  methods: {
    // Speaktext() {
    //   if (this.answer === "开始说话") {
    //     this.start();
    //     this.answer = "停止说话";
    //   } else {
    //     this.answer = "开始说话";
    //     this.end();
    //   }
    // },
    wenzijieguan() {
      Livetakeover({
        sessionId: this.sessionId,
        content: this.text,
        backUrl: "https://mrstage-oss.oss-cn-shanghai.aliyuncs.com/000157742/%E7%BB%BF%E8%89%B2%E8%83%8C%E6%99%AF.png",
      }).then((res) => {
        console.log(res);
      });
    },
    initST() {
      this.loading = true;
      validAccessToken()
        .then((res) => {
          console.log(res);
          localStorage.setItem("token", res.token);
          this.tokens = res.token;
          this.demoFn(res.token);
        })
        .catch(() => {
          this.loadingText = "鉴权接口失败请刷新重试";
          console.log(123213);
        });
    },
    startSt() {
      startPlay(this.sessionId)
        .then(async (res) => {
          console.log(res);
          //   if (res.code === 0) {

          //   }
          Agora_sdk.joinRoom({ channel: null, uid: null });
          this.initmkfws();
        })
        .catch(() => {
          this.loadingText = "开启直播失败请刷新重试";
        });
      Agora_sdk.userPublished = (val) => {
        if (val.mediaType === "audio") {
          val.user.audioTrack.play();
        } else {
          const videoTrack = val.user.videoTrack;
          videoTrack.play("playVideoqny", {
            fit: "contain",
          });
        }
        this.loading = false;
      };
    },
    demoFn(token) {
      qnyCreateV2({
        type: "2",
        backUrl: "https://mrstage-oss.oss-cn-shanghai.aliyuncs.com/000157742/%E7%BB%BF%E8%89%B2%E8%83%8C%E6%99%AF.png",
        url: "http://mrstage-oss.oss-cn-shanghai.aliyuncs.com/nocode/20230918/9%E6%9C%8818%E6%97%A5(2)_wqSwFRMcHP.MP3"
      }).then((res) => {
        console.log(res, "3213123123s");
        this.sessionId = res.data.sessionId;
        this.sessionToken = res.data.sessionToken;
        clearInterval(this.timers);
        let flags = false;
        this.timers = setInterval(() => {
          heartbeat(res.data.sessionId).then((res1) => {
            // console.log(res1, "3123213res1");
          });
        }, 10000);
        Agora_sdk.options.appid = res.data.rtcAppId;
        Agora_sdk.options.channel = res.data.rtcChannelId;
        Agora_sdk.options.token = res.data.rtcToken;
        Agora_sdk.options.uid = res.data.rtcUid;
        Agora_sdk.options.role = "host";
        this.startSt();
      });
    },
    async changeupLoadImg(val, fileindex) {
      // 获取上传的文件
      const inputFile = await this.$refs.fileRef.files[0];

      this.uploadfile3(inputFile).then((loadurl) => {
        console.log(loadurl);
        this.$refs.fileRef.value = "";
        Livetakeover({
          sessionId: this.sessionId,
          type: "2",
          url: loadurl,
        }).then((res) => {
          console.log(res);
        });
      });
    },
    initmkfws() {
      this.wsmic = new WebSocket(
        `wss://aigc.softsugar.com/api/live-takeover-cc/v1/material/live/channel/command/microphone?clientType=BROWSER&sessionId=${this.sessionId}&Authorization=${this.sessionToken}`
      );
      this.wsmic.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        console.log(msg);
        // if (msg.sessionId) {
        //   this.sessionId = msg.sessionId;
        //   ws.send(
        //     JSON.stringify({
        //       type: 1,
        //       sessionId: this.sessionId,
        //       param: JSON.stringify(this.obj),
        //     })
        //   );
        // }
        // if (msg.appId) {
        //   Agora_sdk.options.appid = msg.appId;
        //   Agora_sdk.options.channel = msg.channelId;
        //   Agora_sdk.options.role = "host";
        //   this.startSt();
        // }
        // if (msg.error) {
        //   // this.$message(msg.error);
        //   this.loadingText = msg.error;
        // }
      };
      this.wsmic.onopen = () => {
        // ws.send(
        //   JSON.stringify({
        //     type: "0",
        //     // sessionId: "sessionId",
        //     param: "ping",
        //   })
        // );
      };
      this.wsmic.onerror = (err) => {
        console.log(err, "onerror");
      };
      this.wsmic.onclose = (err) => {
        console.log(err, "onclose");
      };
      // setTimeout(() => {
      // }, 5000);
    },
    maikefengjieguan() {
      if (!this.micFlag) {
        this.recorders.start();
      } else {
        this.recorders.stop();
      }
      this.micFlag = !this.micFlag;
    },
    // 初始化麦克风
    recOpen() {
      const _this = this;
      // 录音对象
      var Recorder = function (stream) {
        var sampleBits = 16; // 输出采样数位 8, 16
        var sampleRate = 16000; // 输出采样率
        var context = new AudioContext();
        var audioInput = context.createMediaStreamSource(stream);
        var recorder = context.createScriptProcessor(4096, 1, 1);
        var audioData = {
          size: 0, // 录音文件长度
          buffer: [], // 录音缓存
          inputSampleRate: 48000, // 输入采样率
          inputSampleBits: 16, // 输入采样数位 8, 16
          outputSampleRate: sampleRate, // 输出采样数位
          oututSampleBits: sampleBits, // 输出采样率
          clear: function () {
            this.buffer = [];
            this.size = 0;
          },
          input: function (data) {
            this.buffer.push(new Float32Array(data));
            this.size += data.length;
          },
          compress: function () {
            // 合并压缩
            var data = new Float32Array(this.size);
            var offset = 0;
            for (var i = 0; i < this.buffer.length; i++) {
              data.set(this.buffer[i], offset);
              offset += this.buffer[i].length;
            }
            // 压缩
            var compression = parseInt(
              this.inputSampleRate / this.outputSampleRate
            );
            var length = data.length / compression;
            var result = new Float32Array(length);
            let index = 0;
            let j = 0;
            while (index < length) {
              result[index] = data[j];
              j += compression;
              index++;
            }
            return result;
          },
          encodePCM: function () {
            var sampleBits = Math.min(
              this.inputSampleBits,
              this.oututSampleBits
            );
            var bytes = this.compress();
            var dataLength = bytes.length * (sampleBits / 8);
            var buffer = new ArrayBuffer(dataLength);
            var data = new DataView(buffer);
            var offset = 0;
            for (var i = 0; i < bytes.length; i++, offset += 2) {
              var s = Math.max(-1, Math.min(1, bytes[i]));
              data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
            }
            return new Blob([data]);
          },
        };
        var sendData = function () {
          // 对以获取的数据进行处理(分包)
          var reader = new FileReader();
          reader.readAsArrayBuffer(audioData.encodePCM());
          reader.onloadend = function () {
            var binary = reader.result;
            var array = new Uint8Array(binary);
            _this.bol = array.every((item) => {
              return item === 0;
            });
            if (_this.wsmic === null) {
              recorder.disconnect();
            } else {
              if (_this.bol) {
                _this.finishConfirm();
              }
              _this.wsmic.send(binary);
            }
          };
          audioData.clear(); // 每次发送完成则清理掉旧数据
        };
        this.start = function () {
          audioInput.connect(recorder);
          console.log(audioInput, "audioInput");
          console.log(context, "context");
          recorder.connect(context.destination);
          console.log(recorder, "recorder");
        };
        this.stop = function () {
          recorder.disconnect();
        };
        this.getBlob = function () {
          return audioData.encodePCM();
        };
        this.clear = function () {
          audioData.clear();
        };
        this.getaudio = function () {
          audioData.getFullWavData();
        };
        recorder.onaudioprocess = function (e) {
          var inputBuffer = e.inputBuffer.getChannelData(0);
          audioData.input(inputBuffer);
          sendData();
        };
      };
      navigator.getUserMedia(
        {
          audio: true,
        },
        (mediaStream) => {
          this.initRecorder(new Recorder(mediaStream));
        },
        (error) => {
          switch (error.message || error.name) {
            case "PERMISSION_DENIED":
            case "PermissionDeniedError":
              this.$message.error("用户拒绝提供信息");
              break;
            case "NOT_SUPPORTED_ERROR":
            case "NotSupportedError":
              this.$message.error("浏览器不支持硬件设备");
              break;
            case "MANDATORY_UNSATISFIED_ERROR":
            case "MandatoryUnsatisfiedError":
              this.$message.error("无法发现指定的硬件设备");
              break;
            default:
              this.$message.error("无法打开麦克风");
              break;
          }
        }
      );
    },
    // 音频获取出错
    finishConfirm() {
      if (!this.isClicked) {
        this.$confirm("获取麦克风音频失败，请检查设置。", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          showCancelButton: false,
          type: "warning",
        })
          .then(() => { })
          .catch(() => {
            this.isClicked = false;
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
      this.isClicked = true;
    },
    // 初始化recorders
    initRecorder(rec) {
      this.recorders = rec;
    },
  },
};
</script>

<style lang="less" scoped>
.demo {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;

  .demo_left {
    width: 500px;
    height: 500px;

    #playVideoqny {
      width: 500px;
      height: 500px;
    }
  }

  .demo_right {
    flex: 1;
    overflow: auto;

    .righttop {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;

      .iptupload {
        position: relative;

        .file-btn {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          cursor: pointer;
        }
      }

      .ipt {
        height: 100%;
      }

      .el-button {
        height: 100%;
      }
    }
  }
}
</style>
import { get_sts_token } from '@/api/user.js'
import { parseTime } from '@/utils/index.js'
import OSS from '@/utils/aliyun-oss-sdk-6.16.0.min.js'
let client
let continuationToken = null
export const Upload = {
  components: {},
  data() {
    return {
      nameRadio: '1',
      dev: false,
      directory: 'nocode',
      imgurl3: '',
      fileurl3: '',
      progress: 0
    }
  },
  mounted() {
    this.getSTS()
    this.setThePath()
  },

  methods: {
    // 获取上传权限
    getSTS() {
      get_sts_token()
        .then((res) => {
          this.initClient(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    initClient(thedata) {
      const creds = thedata.Credentials
      client = new OSS({
        bucket: 'mrstage-oss',
        region: 'oss-cn-shanghai',

        accessKeyId: creds.AccessKeyId,
        accessKeySecret: creds.AccessKeySecret,
        stsToken: creds.SecurityToken,
        refreshSTSToken: async () => {
          // this.getSTS();
          console.log('refreshSTSToken 过期')
          const res = await get_sts_token()
          const refreshToken = res.data.Credentials
          return {
            accessKeyId: refreshToken.AccessKeyId,
            accessKeySecret: refreshToken.AccessKeySecret,
            stsToken: refreshToken.SecurityToken
          }
        }
      })
    },
    async getFileList() {
      const result = await client
        .listV2({
          'continuation-token': continuationToken,
          'max-keys': 20
        })
        .catch((err) => {
          console.log('listV2 error:', err)
        })
      continuationToken = result?.nextContinuationToken
    },
    // 处理图片
    async cascade() {
      this.$router.push({ path: '/ossimg' })
    },
    setThePath() {
      // const d = new Date("2021/08/23");
      const d = new Date()
      const path = parseTime(d, '{y}{m}{d}/')
      // const filename = "nocode/" + path + data.name;
      this.directory = this.dev
        ? this.directory + '/' + 'test/'
        : this.directory + '/' + path
    },
    prefix(prefix) {
      var cdate = new Date()
      var offdate = new Date(2020, 1, 1)
      var offset = cdate.getTime() - offdate.getTime()
      var hexd = parseFloat(offset).toString(16)
      if (typeof prefix === 'string' || typeof prefix === 'number') {
        return prefix + hexd
      } else {
        return 'sui_' + hexd
      }
    },

    getThePath(name = '') {
      let filename = ''
      if (this.nameRadio === '1') {
        filename = this.directory + name
      } else if (this.nameRadio === '2') {
        filename = this.directory + this.prefix('sh') + '/' + name
      } else {
        filename =
          this.directory + this.prefix('sh') + '.' + name.split('.').pop()
      }
      return filename
    },

    // 分片上传
    async uploadfile3(file) {
      const data = file
      // this.$genID(1)
      const fix = data.name.split('.')[data.name.split('.').length - 1]
      const filename = this.getThePath(`${this.$genID(1)}.${fix}`)
      // return;
      let checkpoint
      const theCheckpoint = localStorage.getItem(filename)
      if (theCheckpoint) {
        checkpoint = JSON.parse(theCheckpoint)
      }
      const progress = (p, _checkpoint) => {
        this.progress = (100 * p).toFixed(2)

        checkpoint = _checkpoint
        localStorage.setItem(filename, JSON.stringify(_checkpoint))
      }
      let result = {}
      try {
        result = await client.multipartUpload(filename, data, {
          checkpoint,
          progress
        })
      } catch (err) {
        // 捕获超时异常。
        if (err.code === 'ConnectionTimeoutError') {
          console.log('TimeoutError', err)
          this.$message.error('网络异常上传失败')
          console.log(this.screenList, 'this.screenListthis.screenList');
          this.screenList.map(item => {
            if (item.imgList.length !== 0 && item.imgList.percentageNum !== 100) {
              const newArr = item.imgList.filter(v => v.percentageNum === 100)
              item.imgList = newArr
              this.CHANGE_SCREEN_SETTING({ key: 'screenList', value: this.screenList })
            }
          })
        } else if (err.code === 'RequestError') {
          console.log('connected: false ', err)
          console.log('网络连接异常')
        } else {
          console.log('上传失败', err)
          console.log('接口状态后过期，需要刷新后使用')
          client = null
          continuationToken = null
          this.nameRadio = '1'
          this.dev = false
          this.directory = 'nocode'
          this.imgurl3 = ''
          this.fileurl3 = ''
          this.progress = 0
          this.getSTS()
          this.setThePath()
        }
      }

      if (!result.name) return
      const { name } = result
      localStorage.removeItem(name)
      const url = `https://mrstage-oss.oss-cn-shanghai.aliyuncs.com/${name}`
      return url
    }
  }
}

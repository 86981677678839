import request from '@/utils/request'

// 获取阿里云去上传的权限
export const get_sts_token = (data, config) =>
  request.get('/api/get/sts/token', data)
// export function login(data) {
//   return request({
//     url: '/vue-admin-template/user/login',
//     method: 'post',
//     data
//   })
// }

// export function getInfo(token) {
//   return request({
//     url: '/vue-admin-template/user/info',
//     method: 'get',
//     params: { token }
//   })
// }

// export function logout() {
//   return request({
//     url: '/vue-admin-template/user/logout',
//     method: 'post'
//   })
// }

// 登录
export const Login_in = (data) => request.post('api/ordinary/login', data)
// 登录 type；0
export function Login_old(data) {
  return request({
    url: 'api/login',
    method: 'post',
    data
  })
}
// 注册
export const Register = (data) => request.post('/register', data)
// 修改用户信息
export const user_info = data =>
  request.post('api/update/row/by/id', data)
// 查询用户信息
export const user_filter = (data) => request.post('api/get/filter/rows', data)
// 获取验证码
export function get_phone_code(data) {
  return request({
    url: '/mrstage-base/sms/sendSms',
    method: 'post',
    data
  })
}
// 修改密码接口(新)
export function setPassword(data) {
  return request({
    url: '/jeecg-boot/sys/resetPassWord',
    method: 'post',
    data
  })
}
// 验证码登录
export function phone_code_login(data) {
  return request({
    url: '/api/auth',
    method: 'post',
    data
  })
}

// 设置密码接口
export function set_pass_word(data) {
  return request({
    url: '/api/v2/user/set/password',
    method: 'post',
    data
  })
}

// 创建订单
export const pay_createrow = (data) => request.post('/api/create/row', data)

// 支付宝支付
export const pay_suihuancreateorders = (data) => request.post('api/pay/suihuancreateorders', data)
